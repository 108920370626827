import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AUTH_PREFIX_PATH } from "../../Config/AppConfig";
import { Dropdown, Menu, notification } from "antd";
import { UserOutlined, DownOutlined, LogoutOutlined, HomeOutlined } from "@ant-design/icons";
import { CustomAlert, UpdateAlert } from "../Global/CustomAlert";
import digitreeLogo from "../../../src/assets/digitreeLogo.svg";

function Nav({ routeKey, isEditing }) {
  const navigate = useNavigate();
  const username = sessionStorage.getItem("user_name");
  const [alertConfig, setAlertConfig] = useState({ show: false, type: "", message: "" });

  const handleLogoutClick = () => {
    if (isEditing && parseInt(localStorage.getItem("notEqualCount") || "0", 10) > 1) {
      setAlertConfig({ show: true, type: "unsaved", message: "Please save your information before logging out." });
    } else {
      setAlertConfig({ show: true, type: "logout", message: "Are you sure you want to log out?" });
    }
  };

  const handleConfirmLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    setAlertConfig({ show: false });
    navigate(`${AUTH_PREFIX_PATH}/login`);
    notification.success({
      message: "Success",
      description: "Logout successful!",
    });
  };

  const handleHomeClick = (e) => {
    if (isEditing && parseInt(localStorage.getItem("notEqualCount") || "0", 10) > 1) {
      e.preventDefault();
      setAlertConfig({ show: true, type: "unsaved", message: "Please save your information before navigating home." });
    } else {
      navigate("/dashboard/home");
    }
  };

  const handleDismissAlert = () => {
    setAlertConfig({ show: false });
  };

  const menu = (
    <Menu
      style={{
        padding: "10px 20px",
        textAlign: "center",
        borderRadius: "0px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        width: "160px",
      }}
    >
      <Menu.Item
        key="logout"
        icon={<LogoutOutlined style={{ fontSize: "18px", marginRight: "10px" }} />}
        onClick={handleLogoutClick}
        style={{ fontSize: "16px", color: "#ff4d4f", display: "flex", alignItems: "center" }}
      >
        Log Out
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="bg-black h-18 flex items-center justify-between sticky top-0 z-50 w-full max-w-full">
        <header className="flex items-center justify-between w-full mx-3 px-6 py-2">
          <div className="flex gap-3 items-center justify-start w-full max-w-full">
            <Link to="/dashboard/home" className="app_name text-dark-7 text-[17px]">
              <img
                src={digitreeLogo}
                alt="App Logo"
                className="w-[120px] h-[40px] sm:w-[150px] sm:h-[50px] lg:w-[158px] lg:h-[50px] mx-auto"
              />
            </Link>
          </div>

          <div className="flex items-center space-x-4 ml-auto">
            <Link
              to="/dashboard/home"
              onClick={handleHomeClick}
              style={{ textDecoration: "none" }}
              className="text-white text-lg font-medium items-center ml-6 hover:brightness-50 hidden lg:flex"
            >
              <HomeOutlined className="text-xl mr-2" />
              Home
            </Link>
            <Link
              to="/dashboard/home"
              onClick={handleHomeClick}
              style={{ textDecoration: "none" }}
              className="text-white text-lg font-medium flex items-center ml-6 hover:brightness-50 lg:hidden"
            >
              <HomeOutlined className="text-xl mr-2" />
            </Link>

            <Dropdown
              overlay={menu}
              trigger={["click"]}
              placement="bottomRight"
              overlayClassName="custom-dropdown"
              className="hover:brightness-50"
            >
              <span className="flex items-center text-white cursor-pointer mr-12 text-lg space-x-2">
                <UserOutlined className="text-xl" />
                <span className="text-lg font-medium">{username}</span>
                <DownOutlined className="text-xl ml-1" />
              </span>
            </Dropdown>
          </div>
        </header>
      </div>

      {alertConfig.show && (
        alertConfig.type === "logout" ? (
          <CustomAlert
            title="Log Out"
            message={alertConfig.message}
            onConfirm={handleConfirmLogout}
            onCancel={handleDismissAlert}
          />
        ) : (
          <UpdateAlert
            title="Unsaved Changes"
            message={alertConfig.message}
            onConfirm={handleDismissAlert}
          />
        )
      )}
    </>
  );
}

export default Nav;
